import React from 'react';
import Background from '../../images/impact-background.png';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {device} from '../../models/devices';

const Container = styled.section`
  background: url(${Background}) no-repeat bottom center;
  background-size: cover;
  padding-bottom: 152px;

  @media screen and ${device.tablet} {
    padding-bottom: 80px;
    background-position-y: -80px;
    border-bottom: 1px solid #e6ecfb;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 137px;
  grid-template-areas:
    'header image'
    'text image'
    'list image';

  @media screen and ${device.tablet} {
    grid-template-areas:
      'header'
      'text'
      'list'
      'image'
  ;
    grid-template-columns: 100%;
    padding: 115px 24px 0;
  }
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.33;
  color: white;
  grid-area: header;
  margin-bottom: 24px;

  @media screen and ${device.tablet} {
    font-size: 32px;
    margin-top: 0;
    line-height: 1.5;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.78;
  color: #cfe7fc;
  grid-area: text;
  letter-spacing: -0.1px;
  margin-bottom: 58px;

  @media screen and ${device.tablet} {
    font-size: 16px;
    margin-bottom: 23px;
  }
`;

const ListItem = styled.li`
  font-size: 16px;
  font-weight: 500;
  color: white;
  
  p { display: inline-block; }
  
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    margin-right: 12px;
    border-radius: 50%;
    background: radial-gradient(circle,
      rgba(0,197,245,1) 0%,
      rgba(0,197,245,1) 35%,
      rgba(0,197,245,0.34) 35%,
      rgba(0,197,245,0.34) 100%
    );
    flex-grow: 0;
    flex-shrink: 0;
  }

  @media screen and ${device.tablet} {
    display: flex;
    align-items: baseline;
  }
`;

const Image = styled.img`
  max-width: 571px;
  grid-area: image;
  margin-top: 61px;
  width: 100%;

  @media screen and ${device.tablet} {
    margin: 40px auto 0;
  }
`;

export const TitleSection = (props: {
  title: string;
  text: string;
  image: string;
  alt: string;
  list: string[];
}): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <Text><ReactMarkdown>{props.text}</ReactMarkdown></Text>
        <ul style={{gridArea: 'list'}}>
          {
            props.list.map((text, index) => (
              <ListItem key={index}><ReactMarkdown>{text}</ReactMarkdown></ListItem>
            ))
          }
        </ul>
        <Image src={props.image} alt={props.alt}/>
      </Wrapper>
    </Container>
  );
};
